.active-dropdown {
    background-color: white;
    border: none;
    text-align: center;
    text-decoration: none;
}

.active-dropdown a {
    color: #336699;
}

.active-dropdown ul {
    background-color: white;
    border-top: solid 1px white;
    border-right: solid 1px #336699;
    border-bottom: solid 1px #336699;
    border-left: solid 1px #336699;
    color: #336699;
    display: block;
    list-style: none;
    padding-right: -2px;
}

.dropdown, .active-dropdown {
    animation-duration: 250ms;
    animation-name: showIn;
    position: absolute;
    width: 31.75%;
}

.dropdown th, .active-dropdown th {
    background-color: none;
}

.dropdown ul {
    background-color: #336699;
    color: white;
    display: block;
    list-style: none;
}

@keyframes showIn {
    from {
        display: fixed;
        opacity: 0;
    }

    to {
        display: block;
        opacity: 1;
    }
}