.card {
  /* align-items: center; */
  /* background-color: bisque; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    /* padding: 0 2rem 2rem 2rem; */
    padding: 0%;
    width: 25rem;
  }
  
  .card img {
    border-radius: 5px;
    /* margin-top: -20%; */
    width: 100%;
  }
  
  .card h2 {
    margin: 0;
    margin-top: 1rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

@media (max-width: 1170px) {
  .card {
    width: 8rem;
  }

  .card img {
    width: 95vw;
  }
}
  