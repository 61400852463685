/* TEMP: do not forget to remove later */
.menu > .container-flags > .flags {
    z-index: 10;
    position: absolute;
    top: 0;
    right: -3.7%;
}

.menu {
    background-color: #369;
    bottom: 8%;
    color: white;
    height: 1%;
    padding: 0% 0% 2% 0%;
    position: fixed;
    right: 0%; /* Controls right separation */
    top: 0%;
    width: 100%;
    z-index: 1;
}

.menu-top-navbar {
    background-color: #336699;
    display: flex;
    justify-content: center;
    width: 100%;
}

.menu-top-navbar img {
    width: 5%;
}

.menu-top-navbar .yellow-bar {
    background-color: yellow;
    border: solid 1px yellow;
    height: 2px;
    margin-top: 2.5%;
    width: 50%;
}

@media (max-width: 1170px) {
    /* TEMP: Do not forget to remove later */
    .menu > .container-flags > .flags {
        z-index: 10;
        position: absolute;
        top: 0.1em;
        right: -14%;
        width: 30%;
    }

    .menu {
        height: 5%;
        left: 0%;
        padding: 2% 0% 2% 0%;
        width: 100vw;
        z-index: 3;
    }

    .menu:before {
        height: 5%;
    }

    .menu:after {
        height: 15%;
    }

    .menu img {
        height: auto;
        z-index: 4;
    }

    .menu-top-navbar img {
        width: 15%;
    }

    .menu-top-navbar .yellow-bar {
        height: 2px;
        margin-top: 7%;
        width: 40%;
    }

}