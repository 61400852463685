.footer-final {
    display: grid;
    grid-template-columns: 73% 13% 14%;
}

.footer-row {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.footer-row-media {
    align-items: center;
    display: grid;
    /* grid-template-rows: repeat(4, auto); */
    grid-template-columns: 20% 20% 20% 20% 20%;
    justify-content: center;
    text-align: center;
}

.privacyPolicy:hover, .termsConditions:hover {
    text-decoration: underline;
}

footer {
    background-color: #336699;
    padding: 1% 5% 1% 5%;
    display: grid;
    color: white;
}

footer > hr {
    background-color: white;
    border-color: white;
    color: white;
}

@media (max-width: 1170px) {
    .footer-final {
        grid-template-columns: 75%;
        font-size: small;
        display: space-between;
    }
}