.top-arrow {
    position: fixed;
    color: white;
    right: 1%;
    bottom: 4%;
    width: 4%;
    height: auto;
    background-color: #336699;
    transform: rotate(180deg);
    border-radius: 8px;
    opacity: .8;
}

.top-arrow:hover {
    cursor: pointer;
}

.top-arrow img {
    position: relative;
    width: 100%;
}

@media (max-width: 768px) {
    .top-arrow {
        right: 3%;
        /* bottom: 12%; */
        width: 13%;
    }
}