.contact-whatsapp {
    cursor: pointer;
    bottom: 11%;
    margin-left: 90%;
    position: fixed;
    right: 0.8%;
    width: 4%;
    padding: none;
}

.contact-whatsapp img {
    width: 100%;
}

@media (max-width: 768px) {
    .contact-whatsapp {
      /* bottom: 20%; */
      position: fixed;
      right: 0.8%; /* Controls right separation */
      width: 16%;
    }
}