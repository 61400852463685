.prices-component {
    /* border: solid 1px green; */
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    left: 0%;
    padding: 1%;
    position: static;
    width: 100%;
}

/* BOX PROPERTIES */
.prices-shared-box, .prices-private-box {
    border-radius: 15px;
    text-align: center;
}

.prices-shared-box h3, .prices-private-box h3 {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
}

/* CONDITIONS PROPERTIES */
.prices-shared-conditions, .prices-private-conditions {
    align-items: center;
    justify-content: center;
    margin-left: 10%;
}

/* LISTS BULLETS */
.prices-shared-conditions ul, .prices-private-conditions ul {
    display: grid;
    line-height: 150%;
    list-style: none;
}

/* CUSTOMIZED BULLETS */
.prices-shared-conditions li::before{
    color: #E15E06;
    content: '\2022';
    display: inline-block;
    font-weight: bolder;
    margin-left: -1em;
    width: 1em;
}

.prices-private-conditions li::before{
    color: #336699;
    content: '\2022';  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    display: inline-block; /* Needed to add space between the bullet and the text */
    font-weight: bolder; /* If you want it to be bold */
    margin-left: -1em;
    width: 1em; /* Also needed for space (tweak if needed) */
}

/* ---------------------- */
/* SELECTINC BOXES COLORS */
.prices-shared-box {
    border: solid 3px #FF6600;
}

.prices-private-box {
    border: solid 3px #336699;
}

.prices-shared-box h3 {
    background-color: #FF6600;
}

.prices-private-box h3 {
    background-color: #336699;
}

.prices-shared-conditions, .prices-private-conditions {
    border-left: solid 1px #336699;
}

.prices-shared-condtions ul li::before {
    color: #FF6600;
}

.prices-private-condtions ul li::before {
    color: #336699;
}

@media (max-width: 1170px) {
    .prices-component {
        display: block;
        position: relative;
        left: -5%;
        width: 110%;
    }
    /* .prices-shared-box, .prices-private-box {
        width: 110%;
    } */
    .prices-shared-box h3, .prices-private-box h3 {
        margin: 0% 0% 0% 0%;
    }
    .prices-shared-box p, .prices-private-box p {
        margin: 0% 0% 0% 0%;
    }
    .prices-shared-box h3, .prices-private-box h3, .prices-shared-box p, .prices-private-box {
        font-size: 25px;
    }
    .prices-shared-conditions, .prices-private-conditions {
        border-left: none;
        margin-bottom: 1em;
        margin-top: 0.5em;
        margin-left: 0%;
    }
    .prices-shared-conditions ul, .prices-private-conditions ul {
        display: block;
        text-align: left;
    }
    .prices-shared-conditions ul li, .prices-private-conditions ul li {
        padding-left: 8%;
    }
}