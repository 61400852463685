.card-body {
    text-align: justify;
}

.wrap-tour-container {
    margin-top: 5vh;
    padding: 0% 2.5% 3% 2.5%;
}

.wrap-tour-img {
    width: 100%;
}

.wrap-tour-img img {
    width: 100%;
}