a {
    color: white;
    text-align: center;
    text-decoration: none;
}
a:hover {
    color: white;
}

nav {
    background-color: #336699;
    margin-bottom: 0%;
    padding: 0%;
}

nav > ul {
    display: grid;
    /* grid-template-columns: 20% 20% 20% 20% 20%; */
    grid-template-columns: 33% 34% 33%;
    margin-bottom: 0%;
    padding: 0% 2% 0% 2%;
}

.active {
    background-color: white;
    border: none;
    text-align: center;
    padding: 3% 0% 3% 0%;
    text-decoration: none;
}

.active a {
    color: #336699;
}

.container-flags {
    display: flex;
    justify-content: right;
}

.dropdowns {
    background-color: transparent;
    display: grid;
    grid-template-columns: 32.9% 32.9%;
    padding-left: 2%;
}

.flags {
    position: relative;
    border: solid 1px green;
    top: -18.5vh;
    width: 7.5%;
}

.flags img {
    height: 100%;
    width: 50%;
}

.nav-item {
    border: solid 1px white;
    color: white;
    padding: 3% 0% 3% 0%;
    text-align: center;
    text-decoration: none;
    transition: color 500ms;
}

.nav-item:hover {
    cursor: pointer;
}

@media (max-width: 1080px) {
    ul {
        display: grid;
        grid-template-columns: 33% 34% 33%;
        margin-bottom: 0%;
        padding: 0%;
    }
    .flags {
        top: -12.5vh;
        width: 25%;
    }
}